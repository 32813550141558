import { IHasDescriptionOptional, IHasId, IHasName, IHttpRequestServiceOptions } from 'interfaces';
import { convertService, httpRequestService } from 'services';

export interface ITimeTrackingProject extends IHasId, IHasName, IHasDescriptionOptional {
  clientId: string;
}

const baseUrl = '/time-entries/projects';

const projects = {

  get: (query: {
    isTimeTracking? : boolean; 
  } = {},
  params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<ITimeTrackingProject[]>(`${baseUrl}?`+
                 convertService.toQueryBoolean(query.isTimeTracking, 'isTimeTracking'), params,)
      .then(response => response.data)
  )

};

export default projects;
