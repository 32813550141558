import { IBasePerson, IHasEmail, IHttpRequestServiceOptions, IUser } from 'interfaces';
import { convertService, httpRequestService } from 'services';
import notifications from './notifications';
import { EHttpRequestName } from 'enums';
import current from './current';

const baseUrl = '/users';

const users = {

  update: (userId: string, data: Omit<IBasePerson, 'id'> & IHasEmail, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${userId}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.UpdateUser
        },
        {
          data,
          method: 'PUT'
        }
      )
      .then(response => response.data),

  get: (
    query: {
      idsExcluded?: string[];
      ids?: string[];
      isTimeTracking? : boolean; 
    } = {},
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<IUser[]>(
        [
          `${baseUrl}?`,
          convertService.toQueryString(query.idsExcluded, 'idsExcluded'),
          convertService.toQueryString(query.ids, 'ids'),
          convertService.toQueryBoolean(query.isTimeTracking, 'isTimeTracking')
        ]
          .filter(l => l)
          .join('&')
        ,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetUsers
        }
      )
      .then(response => response.data)
      .then(
        data => (
          data
            .map(
              l => ({
                ...l,
                photo: l.photo?.toBase64()
              })
            )
        )
      ),

  notifications,
  current

};

export default users;
