import { ConsultantUsersSharedWithForm } from 'components/forms';
import { formPanelService, httpRequestService } from 'services';
import { UseFormProps, useForm } from 'react-hook-form';
import { IConsultantUserSharedWith } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';
import { EAccessMode } from 'enums';

interface ICallback {
  onSuccess?: (data: IConsultantUserSharedWith[]) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      role: EAccessMode.ReadOnly,
      userIds: []
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<{ role: EAccessMode; userIds: string[]; }>
};

const useConsultantSharedWithUsersForm = () => {

  const { handleSubmit, control, reset, setValue } = useForm<{ role: EAccessMode; userIds: string[]; }>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerFormAdd = (consultantId: string, usersSharedWith: IConsultantUserSharedWith[], callback?: ICallback) => {
    reset();
    formPanelService
      .publish({
        content: (
          <ConsultantUsersSharedWithForm
            control={control as any}
            userIdsExcluded = {usersSharedWith.map(l => l.userId)}
            setValue={setValue} />
        ),
        title: t('common.addUser'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            formData => {
              const data = formData.userIds.map(l => ({ role: formData.role, userId: l }) as IConsultantUserSharedWith);
              
              return (
                httpRequestService
                  .consultancyProfiles
                  .sharing
                  .add(consultantId, data, { showErrorMessage: false })
                  .then(() => callback?.onSuccess?.(data))
              );
            },
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });

  };

  return { triggerFormAdd };

};

export default useConsultantSharedWithUsersForm;
