import HeaderLeftProjects from './HeaderLeftProjects';
import { AuthenticatedComponent } from 'components';
import HeaderLeftModules from './HeaderLeftModules';
import HeaderLeftLogo from './HeaderLeftLogo';
import { EApplicationModule } from 'enums';
import { useSelector } from 'react-redux';
import { Stack } from '@fluentui/react';
import { appStore } from 'store';
import { memo } from 'react';

const HeaderLeft = () => {

  const _currentModule = useSelector(appStore.common.get.currentModule);

  return (
    <>
      <HeaderLeftLogo />
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <AuthenticatedComponent authenticated={<HeaderLeftModules />} />
        {
          _currentModule === EApplicationModule.Projects &&
          <AuthenticatedComponent authenticated={<HeaderLeftProjects />} />
        }
      </Stack>      
    </>
  );

};

export default memo(HeaderLeft);
