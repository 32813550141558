import {
    IHttpRequestServiceOptions,
    ISignatureRequest,
    IWorkItemLink,
    IHasId,
    IHasFileName,
    IHasData,
  } from 'interfaces';
  import { fileFromServerToFile, IFileFromServer } from 'interfaces/file.interface';
  import { IContractCreation } from 'interfaces/contract.interface';
  import { convertService } from 'services';
  import httpRequestService from '.';
  import axios, { Axios, AxiosResponse } from 'axios';

  interface Option {
    value: string;
    label: string;
  }

  type TDataVariables = {
    users : string[];
    projects : string[];
    exportDate: string;
    exportDateEnd : string;
  };
          
  const baseUrl = '/time-entries';
  
  const exports = {
  
    timeExportsTracking: {
  
        downloadCSV: async (selectedUsers : string[], selectedProjects : string[], selectedExportDate : Date, 
                            selectedEndExportDate : Date,
                            params: IHttpRequestServiceOptions = { requestId : "1234" }) =>
          {
            var data : TDataVariables = {
              users : selectedUsers,
              projects : selectedProjects,
              exportDate: selectedExportDate.toLocaleDateString('en-US', {month: '2-digit',year: 'numeric',}),
              exportDateEnd : selectedEndExportDate.toLocaleDateString('en-US', {month: '2-digit',year: 'numeric',})
            }
          await httpRequestService
            .request<Blob>(`${baseUrl}/exporttimesheet/`, 
              params, 
              { method : "POST", data : data , responseType: 'blob', headers: {
                'Content-Type': 'application/json',
              }})//, })
            .then(response => 
              {

                const downloadLink = document.createElement("a");    
                downloadLink.href = URL.createObjectURL(response.data);    
                downloadLink.download = `files.zip`;    
                downloadLink.click();
              });
    //return response.
          }
    },
}
  export default exports;

  