import { EApplicationModule } from 'enums';
import { TRouteExports } from 'types';
import { concatPath } from 'functions';
import { IRoutes } from 'interfaces';
import { ROUTES } from 'constant';
import { lazy } from 'react';

/** Route that redirects to module settings in application. */
const EXPORTS = {
  module: EApplicationModule.Exports,
  id: '/exports',
  /** Parent of route. */
  parent: () => ROUTES as IRoutes,
  /** Base url of route */
  base: '/exports',
  /** Path of route. */
  path: () => concatPath(ROUTES.path(), EXPORTS.base),
  relativePath: () => `exports/*`,
  /** Description of route. */
  description: (t: Function): string => t('common.exports'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeExports'*/ 'modules/exports/routes')),

  TIME_EXPORTS_TRACKING: {
    module: EApplicationModule.Exports,
    id: '/exports/time-exports-tracking',
    /** Parent of route. */
    parent: () => EXPORTS as IRoutes,
    /** Base url of route */
    base: '/time-exports-tracking',
    /** Path of route. */
    path: () => concatPath(EXPORTS.path(), EXPORTS.TIME_EXPORTS_TRACKING.base),
    relativePath: () => 'time-exports-tracking',
    /** Description of route. */
    description: (t: Function): string => t('routes.exports.timeTracking.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeExportsTimeTracking'*/ 'modules/exports/pages/time-exports-tracking'))
  }
} as TRouteExports;

export default EXPORTS;
