import { IHttpRequestServiceOptions, IHttpStatusServiceCommand, IHttpRequestError } from 'interfaces';
import { EHttpRequestName, EHttpResponseStatus } from 'enums';
import identity from 'services/httpRequestService/identity';
import azureAD from 'services/httpRequestService/azureAd';
import devops from 'services/httpRequestService/devops';
import consultancyProfiles from './consultancyProfiles';
import axios, { AxiosRequestConfig } from 'axios';
import { messageBoxService } from 'services';
import nomenclatures from './nomenclatures';
import timeEntries from './time-entries';
import { axiosInstance } from 'config';
import helloSign from './helloSign';
import _projects from './_projects';
import projects from './projects';
import logging from './logging';
import clients from './clients';
import tickets from './tickets';
import { Subject } from 'rxjs';
import skills from './skills';
import users from './users';
import info from './info';
import _exports from './exports';

const VARIABLES = {
  subject: new Subject<IHttpStatusServiceCommand>(),
  publish: (status: IHttpStatusServiceCommand) => VARIABLES.subject.next(status)
};

/** Service used to spread status of http request. */
const httpRequestService = {
  /** Get object to cancel request. */
  getCancelTokenSource: () => axios.CancelToken.source(),
  /** Observator of status published. */
  getStatus: () => VARIABLES.subject.asObservable(),

  /**
   * Execute http request.
   * @param url The URL of request.
   * @param params Params of current request.
   * @param requestConfig Options of http request.
   */
  request: <TResponse>(
    url: string,
    params: IHttpRequestServiceOptions & { httpRequestName?: EHttpRequestName } = {},
    requestConfig: AxiosRequestConfig = {}
  ) => {
    // Update request config
    requestConfig = {
      ...requestConfig,
      url,
      cancelToken: params.cancelToken
    };
    // Update params
    params.showErrorMessage = params.showErrorMessage ?? true;

    // Publish status
    VARIABLES.publish({ requestId: params.requestId, type: 'loading' });
    // Send request
    return axiosInstance
      .request<TResponse>(requestConfig)
      .then(
        response => {
          // Publish command
          VARIABLES.publish({ requestId: params.requestId, type: 'success', data: response.data });
          // Return result
          return response;
        }
      )
      .catch(
        error => {
          // Publish command
          VARIABLES.publish({ requestId: params.requestId, type: 'fail', data: error });

          // Display error message
          params.showErrorMessage &&
            (error as IHttpRequestError)?.Status !== EHttpResponseStatus.Cancelled &&
            messageBoxService.error(
              (error as IHttpRequestError)?.Status !== EHttpResponseStatus.BadRequest
                ? 'An error happened.'
                : (error as IHttpRequestError)?.Title ?? error
            );
          // Throw error
          throw error;
        }
      )
      .finally(() => VARIABLES.publish({ requestId: params.requestId, type: 'completed' }));
  },

  consultancyProfiles,
  nomenclatures,
  timeEntries,
  _projects,
  helloSign,
  projects,
  identity,
  logging,
  clients,
  tickets,
  azureAD,
  skills,
  devops,
  users,
  info,
  _exports
};

export default httpRequestService;
