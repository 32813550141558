import { CommandButton, IContextualMenuItem, Icon, Stack, Text } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { EApplicationModule, EIconName } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constant';
import { appStore } from 'store';
import { memo } from 'react';
import { ModulesUnavailable } from 'types/routeExports.type';

const VARIABLES = {
  getIconName: (module: EApplicationModule) => (
    module === EApplicationModule.Projects
      ? EIconName.ProjectLogo16
      : module === EApplicationModule.Settings
        ? EIconName.Settings
        : module === EApplicationModule.Consulting
          ? EIconName.TextDocumentShared
          : module === EApplicationModule.TimeTracking
            ? EIconName.TimeEntry
            : module === EApplicationModule.Exports
              ? EIconName.TimeEntry
            : EIconName.Unknown
  )
};

const HeaderLeftModules = () => {

  const _modulesUserConnected = useSelector(appStore.userConnected.get.modules);
  const _currentModule = useSelector(appStore.common.get.currentModule);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const memuPropsItems = (
    _modulesUserConnected
      .map(
        l => ({
          key: l,
          data: l,
          className: (
            l === _currentModule
              ? 'ms-bgColor-gray30'
              : ''
          ),
          onRenderContent: () => (
            <Stack
              horizontal
              className='mx-1'
              verticalAlign='center'
              tokens={{ childrenGap: 5 }}>
              <Icon iconName={VARIABLES.getIconName(l)} className='ms-fontSize-12' />
              <Text>
                {t(`common.${l.toLowerCase()}`)}
              </Text>
            </Stack>
          )
        } as IContextualMenuItem)
      )
  );

  const handleOnItemClickSelectModule = (module: EApplicationModule) => {

    if (module !== _currentModule) {
      appStore.common.set.setProjectSelected(dispatch);
      appStore.common.set.setCurrentModule(dispatch, module);
      navigate(ROUTES.path());
    }

  };

  if (ModulesUnavailable.includes(String(_currentModule)) || !_currentModule || memuPropsItems.length <= 1) {
    return <></>;
  }

  return (
      <Stack horizontal verticalAlign='center'>
        <CommandButton
          iconProps={{ iconName: VARIABLES.getIconName(_currentModule) }}
          text={t(`common.${_currentModule.toLowerCase()}`)}
          className='ms-fontWeight-semibold'
          menuProps={{
            items: memuPropsItems,
            onItemClick: (_, item) => item && handleOnItemClickSelectModule(item.key as any)
          }} />
      </Stack>     
    );
};

export default memo(HeaderLeftModules);
