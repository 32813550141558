import { INavLink, INavLinkGroup, INavStyleProps, INavStyles, IStyleFunctionOrObject, Nav, Stack } from '@fluentui/react';
import { useModuleConsulting, useModuleProjects, useModuleSettings, useModuleTimeTracking, useModuleExports } from 'hooks';
import { EAppInitStatus, EApplicationModule } from 'enums';
import { CSSTransition } from 'react-transition-group';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appStore } from 'store';
import './Menu.scss';
import { ModulesUnavailable } from 'types/routeExports.type';

interface IState {
  selectedKeyMenu?: string;
}

const VARIABLES = {
  navStyle: {
    root: { overflowX: 'hidden' },
    chevronIcon: {
      color: 'transparent',
      backgroundColor: 'transparent'
    },
    chevronButton: {
      color: 'transparent',
      backgroundColor: 'transparent'
    }
  } as IStyleFunctionOrObject<INavStyleProps, INavStyles>,
  initState: {
  } as IState
};

const Menu = () => {

  const _userConnected = useSelector(appStore.userConnected.get.userConnected);
  const { menuLinks: menuLinksModuleTimeTracking } = useModuleTimeTracking();
  const { menuLinks: menuLinksModuleConsulting } = useModuleConsulting();
  const _currentModule = useSelector(appStore.common.get.currentModule);
  const _isMenuVisible = useSelector(appStore.common.get.isMenuVisible);
  const _currentRoute = useSelector(appStore.common.get.currentRoute);
  const { menuLinks: menuLinksModuleSettings } = useModuleSettings();
  const { menuLinks: menuLinksModuleProjects } = useModuleProjects();
  const { menuLinks: menuLinksModuleExports } = useModuleExports();

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const _status = useSelector(appStore.startup.get.status);

  const navLinks = (
    (!ModulesUnavailable.includes(String(_currentModule))  ? 
      _currentModule === EApplicationModule.Projects
        ? menuLinksModuleProjects
        : _currentModule === EApplicationModule.Settings
          ? menuLinksModuleSettings
          : _currentModule === EApplicationModule.Consulting
            ? menuLinksModuleConsulting
            : _currentModule === EApplicationModule.TimeTracking
              ? menuLinksModuleTimeTracking
              : _currentModule === EApplicationModule.Exports
              ? menuLinksModuleExports :
              []
    : [])
  );

  useEffect(
    () => {
      console.log("route desc : "+_currentRoute?.description);
      if (_currentRoute?.id) {
        const selectedKeyMenu = navLinks.find(l => (l.routeIds as string[]).some(ll => _currentRoute.id.toLowerCase() === ll.toLowerCase()))?.key;
        setState(l => ({ ...l, selectedKeyMenu }));
      }
    },
    // eslint-disable-next-line
    [
      navLinks.length,
      _currentRoute?.id
    ]
  );

  const handleOnRenderLink = (navLink?: INavLink) => {

    if (!navLink) {
      return null;
    }

    if (navLink.onRenderLink) {
      return navLink.onRenderLink();
    }

    if (_isMenuVisible) {
      return <>{navLink.name}</>;
    }

    return null;

  };

  const navLinkGroups = [
    {
      links: navLinks
    }
  ] as INavLinkGroup[];

  if (!_currentModule) {
    return <></>;
  }

  if (_status !== EAppInitStatus.authenticated) {
    return <></>;
  }

  if (!_userConnected?.id) {
    return <></>;
  }

  if (navLinks.length === 0) {
    return <></>;
  }

  return (
    <CSSTransition classNames='app-menu' in={_isMenuVisible} timeout={300}>
      <Stack className='app-menu' verticalFill horizontal={false}>
        <Stack verticalFill horizontalAlign='stretch' className='app-menu-body'>
          <Nav
            groups={navLinkGroups}
            styles={VARIABLES.navStyle}
            onRenderLink={handleOnRenderLink}
            selectedKey={state.selectedKeyMenu} />
        </Stack>
      </Stack>
    </CSSTransition>
  );

};

export default memo(Menu);
