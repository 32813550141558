import {
  useHttpRequestGetTimeTrackingActivities,
  useHttpRequestGetTimeTrackingClients,
  useHttpRequestGetTimeTrackingProjects
} from 'hooks/httpRequest';
import { ControlledDropdown, ControlledTextField } from 'components';
import { IDropdownOption, Stack, StackItem } from '@fluentui/react';
import { ICreateUpdateTimeEntryFormData } from 'interfaces';
import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameof } from 'functions';

interface IState {
  projectId?: string;
  clientId?: string;
}

const VARIABLES = {
  initState: {
  } as IState
};

const CreateUpdateTimeEntryForm = () => {

  const { control, setValue, watch } = useFormContext<ICreateUpdateTimeEntryFormData>();
  const { data: dataActivities } = useHttpRequestGetTimeTrackingActivities();
  const { data: dataProjects } = useHttpRequestGetTimeTrackingProjects();
  const { data: dataClients } = useHttpRequestGetTimeTrackingClients();
  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  useEffect(
    () => {
      control._defaultValues.clientId && setState(l => ({ ...l, clientId: control._defaultValues.clientId }));
      control._defaultValues.projectId && setState(l => ({ ...l, projectId: control._defaultValues.projectId }));
      const subscription = watch(
        (data, { name }) => {

          if (name === nameof<ICreateUpdateTimeEntryFormData>('clientId')) {
            setState(l => ({ ...l, clientId: data.clientId }));
            setValue('projectId', '');
          } else if (name === nameof<ICreateUpdateTimeEntryFormData>('projectId')) {
            setState(l => ({ ...l, projectId: data.projectId }));
            setValue('activityId', '');
          }

        }
      );

      return () => {
        subscription.unsubscribe();
      };
    },
    []
  );

  const dataOptionClients = (
    dataClients
      .map(
        l => ({
          text: l.name,
          data: l.id,
          key: l.id
        } as IDropdownOption<string>)
      )
  );

  const dataOptionProjects = (
    dataProjects
      .filter(l => l.clientId === state.clientId)
      .map(
        l => ({
          text: l.name,
          data: l.id,
          key: l.id
        } as IDropdownOption<string>)
      )
  );

  const dataOptionActivities = (
    dataActivities
      .filter(l => l.projectId === state.projectId)
      .map(
        l => ({
          text: l.name,
          data: l.id,
          key: l.id
        } as IDropdownOption<string>)
      )
  );

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <ControlledDropdown
        className='w-100'
        control={control as any}
        options={dataOptionClients}
        label={t('common.client')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<ICreateUpdateTimeEntryFormData>('clientId')} />
      <ControlledDropdown
        className='w-100'
        control={control as any}
        options={dataOptionProjects}
        label={t('common.project')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<ICreateUpdateTimeEntryFormData>('projectId')} />
      <ControlledDropdown
        className='w-100'
        control={control as any}
        options={dataOptionActivities}
        label={t('common.activity')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<ICreateUpdateTimeEntryFormData>('activityId')} />
      <Stack horizontal tokens={{ childrenGap: 12 }}>
        <StackItem grow>
          <ControlledTextField
            min={0}
            max={8}
            step={1}
            type='number'
            control={control as any}
            label={t('common.hours')}
            rules={{ required: t('common.formError.required').toString() }}
            name={nameof<ICreateUpdateTimeEntryFormData>('hours')} />
        </StackItem>
        <StackItem grow>
          <ControlledTextField
            min={0}
            max={59}
            step={10}
            type='number'
            control={control as any}
            label={t('common.minutes')}
            rules={{ required: t('common.formError.required').toString() }}
            name={nameof<ICreateUpdateTimeEntryFormData>('minutes')} />
        </StackItem>
      </Stack>
      <ControlledTextField
        multiline
        autoAdjustHeight
        control={control as any}
        label={t('common.description')}
        name={nameof<ICreateUpdateTimeEntryFormData>('description')} />
      <ControlledTextField
        autoAdjustHeight
        control={control as any}
        label='id User'
        value='toto'
        name={nameof<ICreateUpdateTimeEntryFormData>('userId')} />
    </Stack>
  );

};

export default memo(CreateUpdateTimeEntryForm);
