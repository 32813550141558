import { createSelector, createSlice, Dispatch } from '@reduxjs/toolkit';
import { IApiVersion, IProject, IRoutes } from 'interfaces';
import { EApplicationModule, EProjectRole } from 'enums';
import { TNullable } from 'types';
import { IStore } from 'store';

type TProjectSelected = IProject & {
  members: {
    role: EProjectRole;
    userId: string;
  }[];
};

export interface ICommonStore {
  projectSelected?: TProjectSelected;
  currentModule?: EApplicationModule;
  isHeaderLoadingVisible: boolean;
  version?: IApiVersion | null;
  isMenuVisible: boolean;
  currentRoute?: IRoutes;
}

const selectCommon = (state: IStore) => state.common;

const commonSlice = createSlice({
  name: 'common',
  reducers: {
    toggleMenuVisibility: state => {
      state.isMenuVisible = !state.isMenuVisible;
      return state;
    },
    setVersion: (state, action) => {
      state.version = action.payload;
      return state;
    },
    toggleHeaderLoadingVisibility: (state, action) => ({ ...state, isHeaderLoadingVisible: !state.isHeaderLoadingVisible }),
    setProjectSelected: (state, action) => ({ ...state, projectSelected: action.payload }),
    setCurrentModule: (state, action) => ({ ...state, currentModule: action.payload }),
    setCurrentRoute: (state, action) => ({ ...state, currentRoute: action.payload })
  },
  initialState: {
    version: null,
    isMenuVisible: true
  } as ICommonStore
});

export default commonSlice;

const set = {
  toggleMenuVisibility: (dispatch: Dispatch) => (
    dispatch({
      type: `${commonSlice.name}/${set.toggleMenuVisibility.name}`
    })
  ),
  setVersion: (dispatch: Dispatch, payload: IApiVersion) => (
    dispatch({
      type: `${commonSlice.name}/${set.setVersion.name}`,
      payload
    })
  ),
  setProjectSelected: (dispatch: Dispatch, payload?: TProjectSelected) => (
    dispatch({
      type: `${commonSlice.name}/${set.setProjectSelected.name}`,
      payload
    })
  ),
  setCurrentRoute: (dispatch: Dispatch, payload?: IRoutes) => (
    dispatch({
      type: `${commonSlice.name}/${set.setCurrentRoute.name}`,
      payload
    })
  ),
  setCurrentModule: (dispatch: Dispatch, payload?: TNullable<EApplicationModule>) => (
    dispatch({
      type: `${commonSlice.name}/${set.setCurrentModule.name}`,
      payload
    })
  ),
  toggleHeaderLoadingVisibility: (dispatch: Dispatch) => (
    dispatch({
      type: `${commonSlice.name}/${set.toggleHeaderLoadingVisibility.name}`
    })
  )
};

const get = {
  isHeaderLoadingVisible: createSelector(selectCommon, common => common?.isHeaderLoadingVisible ?? false),
  version: createSelector(selectCommon, common => common.version as IApiVersion),
  isMenuVisible: createSelector(selectCommon, common => common.isMenuVisible),
  currentModule: createSelector(selectCommon, common => common.currentModule),
  currentRoute: createSelector(selectCommon, common => common.currentRoute),
  projectSelected: createSelector(
    selectCommon,
    common => ({
      ...common.projectSelected,
      members: common.projectSelected?.members ?? []
    }) as TProjectSelected
  ),
  isProjectAdmin: createSelector(
    (state: IStore) => state,
    state => {
      const _projectSelected = state.common.projectSelected;
      const _idUserConnected = state.userConnected?.id;

      const doesUserAdminExist = (
        _projectSelected
          ?.members
          ?.some(l => l.role === EProjectRole.Administrator && l.userId === _idUserConnected) ?? false
      );

      return doesUserAdminExist;
    }
  )
};

export const commonStore = {
  set,
  get,
};
