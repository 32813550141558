import {CommandButton, Icon, Persona, PersonaSize, Spinner, Stack} from '@fluentui/react';
import {useEventSouscription, useHttpRequestStatus} from 'hooks';
import {emptyFunction, selectCurrentProject} from 'functions';
import {memo, useCallback, useEffect, useMemo} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {httpRequestService} from 'services';
import {EEventType, EIconName} from 'enums';
import {IProject} from 'interfaces';
import {ROUTES} from 'constant';
import {TNullable} from 'types';
import {appStore} from 'store';

const VARIABLES = {
    regexGuid: /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/,
    requestId: 'HeaderProjects.getProjects'
};

const HeaderLeftProjects = () => {

    const {data: dataProjects, isLoading} = useHttpRequestStatus<IProject[]>(VARIABLES.requestId, {
        data: [],
        isLoading: true
    });
    const _modulesUserConnected = useSelector(appStore.userConnected.get.modules);
    const _projectSelected = useSelector(appStore.common.get.projectSelected);
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEventSouscription({
        callback: () => loadProjects(),
        events: [
            EEventType.projectCreated,
            EEventType.projectDeleted,
            EEventType.projectUpdated
        ]
    });

    useEffect(() => loadProjects(), []);

    useEffect(
        () => {

            if ((pathname ?? '').toLowerCase().startsWith(ROUTES.PROJECTS.base.toLowerCase())) {
                const projectId = pathname.split('/').filter(l => l)[1];

                if (projectId && _projectSelected && projectId === _projectSelected.id) {
                    return;
                }

                handleOnItemClickSelectProject(projectId);
            }

        },
        // eslint-disable-next-line
        [pathname]
    );

    const loadProjects = useCallback(
        () => {
            httpRequestService
                ._projects
                .get({}, {requestId: VARIABLES.requestId})
                .catch(emptyFunction);
        },
        []
    );

    const memuPropsItems = useMemo(
        () => (
            (dataProjects ?? [])
                .map(
                    project => ({
                        key: project.id,
                        data: project,
                        className: (
                            project.id === _projectSelected?.id
                                ? 'ms-bgColor-gray30'
                                : ''
                        ),
                        onRenderContent: () => <Persona text={project.displayName} size={PersonaSize.size24}/>,
                        disabled: project.id === _projectSelected?.id
                    })
                )
        ),
        [
            dataProjects,
            _projectSelected?.id
        ]
    );

    const handleOnItemClickSelectProject = (projectId: string, manualAction = false) => {

        if (projectId) {
            selectCurrentProject(httpRequestService, dispatch, projectId)
                .then(
                    () => {
                        let url: TNullable<string> = null;

                        if (pathname.toLowerCase().endsWith(`/projects/${projectId}/`)) {
                            url = ROUTES.PROJECTS.PROJECT.OVERVIEW?.pathValue?.(projectId) ?? null;
                        } else if (pathname.toLowerCase().endsWith('/translations')) {
                            url = ROUTES.PROJECTS.PROJECT.TRANSLATIONS?.pathValue?.(projectId) ?? null;
                        } else if (pathname.toLowerCase().endsWith('/release-notes')) {
                            url = ROUTES.PROJECTS.PROJECT.RELEASE_NOTES?.pathValue?.(projectId) ?? null;
                        } else if (pathname.toLowerCase().endsWith('/settings')) {
                            url = ROUTES.PROJECTS.PROJECT.SETTINGS?.pathValue?.(projectId) ?? null;
                        } else if (!pathname.toLowerCase().includes('/ticket/')) {
                            url = ROUTES.PROJECTS.PROJECT.TICKETS?.pathValue?.(projectId) ?? null;
                        } else if (manualAction && pathname.toLowerCase().split('/ticket/').length > 1) {
                            url = ROUTES.PROJECTS.PROJECT.TICKETS?.pathValue?.(projectId) ?? null;
                        }

                        if (url) {
                            navigate(url);
                        }
                    }
                );
        }

    };

    if (isLoading) {
        return <Spinner className='mx-3'/>;
    }

    if (!_projectSelected?.id || (memuPropsItems?.length ?? 0) === 0) {
        return <></>;
    }

    return (
        <Stack horizontal verticalAlign='center'>
            {
                _modulesUserConnected.length > 1 &&
                <Icon
                    className='my-auto ms-fontSize-12 ms-fontColor-gray50'
                    iconName={EIconName.ChevronRight}/>
            }
            <CommandButton
                iconProps={{iconName: EIconName.ProjectCollection}}
                text={_projectSelected?.displayName}
                menuProps={{
                    items: memuPropsItems,
                    onItemClick: (_, item) => item && handleOnItemClickSelectProject(item.key, true)
                }}/>
        </Stack>
    );

};

export default memo(HeaderLeftProjects);
