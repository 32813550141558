import { TNullable } from 'types';
import { dayjs } from 'config';
import { Bold } from 'ckeditor5';

const convertService = {

  toQueryString: (array?: TNullable<any[]>, name?: string) => {
    const validArray = array?.filter(l => [null, undefined, ''].includes(l) === false) ?? [];

    if (validArray.length === 0) {
      return '';
    }

    return validArray.map(value => `${name}=${value}`).join('&');
  },
  toQueryBoolean: (boolean?: TNullable<Boolean>, name?: string) => {
    if (boolean == undefined)
      return "";
    var returnValue = name+"="+boolean+"&";
    return returnValue;
  },

  ticketNumberToString: (ticketNumber?: TNullable<number | string>) => (
    ticketNumber
      ? `XC${ticketNumber.toString().padStart(4, '0')}`
      : ''
  ),

  convertToDate: (value?: string | Date | null, defaultValue = null) => (
    value
      ? dayjs(value, { utc: true })
        .toDate()
      : defaultValue ?? undefined
  )

};

export default convertService;
