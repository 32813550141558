import imgLogo from 'assets/images/png/Logo-Xchange_horiz.png';
import HeaderLeftLogoBurger from './HeaderLeftLogoBurger';
import { ENVIRONMENT_VARIABLES, ROUTES } from 'constant';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@fluentui/react';
import { EAppInitStatus } from 'enums';
import { appStore } from 'store';
import { memo } from 'react';

const HeaderLeftLogo = () => {

  const _isUserConnected = useSelector(appStore.userConnected.get.isUserConnected);
  const _currentModule = useSelector(appStore.common.get.currentModule);
  const _status = useSelector(appStore.startup.get.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnClickStack = () => {

    if (_status === EAppInitStatus.authenticated && _isUserConnected && _currentModule) {
      appStore.common.set.setProjectSelected(dispatch);
      appStore.common.set.setCurrentModule(dispatch);
      navigate(ROUTES.path());
    }

  };

  return (
    <Stack className='app-header-logo' horizontal>
      <HeaderLeftLogoBurger />      
      <Stack
        grow
        onClick={handleOnClickStack}
        role='button'
        horizontalAlign='center'
        verticalAlign='center'>
        <img src={imgLogo} alt={ENVIRONMENT_VARIABLES.appName} />
      </Stack>
    </Stack>
  );

};

export default memo(HeaderLeftLogo);
