import { Route, Routes } from 'react-router-dom';
import LoadingView from 'components/loadingView';
import RoutesGuard from './RoutesGuard';
import { memo, Suspense } from 'react';
import { ROUTES } from 'constant';

const AppRoutes = () => (
  <Suspense fallback={<LoadingView />}>
    <Routes>
      {/* Loading page of application. */}
      <Route path={ROUTES.relativePath()} element={<RoutesGuard component={<ROUTES.lazyComponent/>} />} />
      {/* Modules of application */}
      <Route path={ROUTES.DASHBOARD.relativePath()} element={<RoutesGuard component={<ROUTES.DASHBOARD.lazyComponent/>} />} />
      <Route path={ROUTES.TIME_TRACKING.relativePath()} Component={ROUTES.TIME_TRACKING.lazyComponent} />
      <Route path={ROUTES.CONSULTING.relativePath()} element={<RoutesGuard component={<ROUTES.CONSULTING.lazyComponent/>} />} />
      <Route path={ROUTES.SETTINGS.relativePath()} element={<RoutesGuard component={<ROUTES.SETTINGS.lazyComponent/>} />} />
      <Route path={ROUTES.PROJECTS.relativePath()} element={<RoutesGuard component={<ROUTES.PROJECTS.lazyComponent/>} />} />
      <Route path={ROUTES.ADMIN.relativePath()} element={<RoutesGuard component={<ROUTES.ADMIN.lazyComponent/>} />} />
      <Route path={ROUTES.EXPORTS.relativePath()} element={<RoutesGuard component={<ROUTES.EXPORTS.lazyComponent/>} />} />      
      {/* Module to handle errors  */}
      <Route path={ROUTES.ERRORS.relativePath()} Component={ROUTES.ERRORS.lazyComponent} />
      {/* Default route when not found */}
      <Route path ="*" Component={ROUTES.NOT_FOUND.lazyComponent} />
    </Routes>
  </Suspense>
);


export default memo(AppRoutes);
